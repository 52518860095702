import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Box, Typography, Chip,
  Tooltip,
  Badge,
  IconButton,
  Avatar,
} from '@mui/material';
import HTMLIcon from '../../assets/html-svgrepo-com.svg';
import XMLIcon from '../../assets/xml-svgrepo-com.svg';
import deleteIcon from '../../assets/delete-icon.svg';
import CancelIcon from '../../assets/circle-xmark.svg';
import excelIcon from '../../assets/excel-svgrepo-com.svg';
import CheckIcon from '../../assets/check-circle.svg';
import jenkinsImg from '../../assets/jenkins.png';
import { CustomPagination, homeStyles, StyledTablePagination } from '../header/style';
import useHandleChipClick from '../../utils/FilterChipHook';
import { useCommonContext } from '../header/context';
import {
  browserEnum,
  browserIcons,
  FileType, plateformIcons, platformEnum, UserRole,
} from '../../utils/constants';
import reportService from '../../services/report.service';
import DeleteReportModal from './DeleteReportModal';
import { exportToExcel } from '../../services/uploadFile.service';

const TestExecutionList = ({
  tests,
  totalPages,
  page,
  onTestClick,
  onPageChange,
  isPagination,
  handleChangeRowsPerPage,
  rowsPerPage,
  setSnackbar,
  isRefresh,
  setIsRefresh,
}) => {
  const classes = homeStyles();
  const { user } = useCommonContext();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [exportExcel, setExportExcel] = useState(false);
  const handleChipClick = useHandleChipClick();

  const handleDeleteReport = async () => {
    try {
      if (selectedTest) {
        await reportService.deleteReportById(selectedTest.fileType, selectedTest.id);
        setIsRefresh(!isRefresh);
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: 'Report Deleted Successfully',
        });
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message || error?.message}`,
      });
    } finally {
      setOpenDeleteModal(false); // Close the modal after the operation
      setSelectedTest(null); // Reset the selected test
    }
  };

  const reportDownload = async (path, reportName, reportKey, fileType) => {
    try {
      setDownloading(true);
      const res = await reportService.downloadPdf(path, reportName, reportKey);
      if (res) {
        const url = new Blob([res], { type: `applictaion/${fileType !== FileType.XML ? 'html' : 'xml'}` });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('download', `${reportName}.${fileType !== FileType.XML ? 'html' : 'xml'}`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message || error?.message}`,
      });
    } finally {
      setDownloading(false);
    }
  };

  const reportExportToExecl = async (id, filename, fileType) => {
    try {
      setExportExcel(true);
      const res = await exportToExcel({
        testReportId: id,
        fileType,
      });
      if (res) {
        const url = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setExportExcel(false);
    }
  };

  return (<>
    {tests?.length ? (
      tests.map((test) => {
        const chips = [
          { label: 'Passed', value: test.passed, className: classes.chipSuccess },
          { label: 'Failed', value: test.failed, className: classes.chipFailure },
          { label: 'Ignored', value: test.ignored, className: classes.chipIgnored },
          { label: 'Skipped', value: test.skipped, className: classes.chipSkipped },
        ];

        const platform = Object.keys(test?.platformNameAndBrowserName
          && Object.keys(test?.platformNameAndBrowserName).length > 0
          ? test.platformNameAndBrowserName
          : { [platformEnum.WINDOWS]: null });

        const browser = Object.values(test?.platformNameAndBrowserName
          && Object.keys(test?.platformNameAndBrowserName).length > 0
          ? test.platformNameAndBrowserName
          : { [platformEnum.WINDOWS]: browserEnum.CHROME });

        const handleOpenDeleteModal = (event) => {
          event.stopPropagation(); // Prevents the parent click event
          setSelectedTest(test); // Set the selected test for deletion
          setOpenDeleteModal(true); // Open the modal
        };
        return (<Paper
          className={classes.latestTestExecution}
          sx={{ cursor: 'pointer' }}
          key={test.id}
          onClick={() => onTestClick(test.id, test.fileType)}
        >
          <Box className={classes.details}>
            <Box display="flex">
              <Typography variant="body1" className={classes.closeIcon}>
                {test.failed ? <img style={{ width: '20px' }} src={CancelIcon} alt="cancel-icon" /> : <img style={{ width: '20px' }} src={CheckIcon} alt="check-icon" />}
              </Typography>
              <Box>
                <Typography variant="body1" fontWeight={600} sx={{ display: 'flex', gap: 2 }}>
                  {test.testReportName}
                  {(platform?.length > 0 && browser?.length > 0)
                    && <>
                      <Tooltip
                        title={platform}
                      >
                        <Avatar
                          src={plateformIcons[platform[0]?.toLowerCase()]}
                          sx={{ width: 18, height: 18, borderRadius: 0 }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={browser}
                      >
                        <Avatar
                          src={browserIcons[browser[0]?.toLowerCase()]}
                          sx={{ width: 18, height: 18, borderRadius: 0 }}
                        />
                      </Tooltip>
                    </>}
                </Typography>
                <Typography variant="body2" color="textSecondary" my={1}>
                  Ran By
                  {` ${test?.customUserResponse?.username} `}
                  on
                  {` ${test.startedAt} `}
                </Typography>
                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant="body2" color="textSecondary" my={1}>
                  <span>
                    <strong>Company: </strong>
                    {test.companyName}
                  </span>
                  {' '}
                  <span>
                    <strong>Project: </strong>
                    {test.projectName}
                  </span>
                </Typography>

              </Box>
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2,
            }}
            >
              <Box className={classes.chips}>
                {chips && chips?.map((item) => (
                  <Tooltip key={item.label} title={item.label}>
                    <Chip
                      onClick={item.value > 0 ? () => handleChipClick(item.label) : undefined}
                      variant="outlined"
                      label={item.value}
                      className={item.className}
                    />
                  </Tooltip>
                ))}
              </Box>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2, px: 1,
              }}
              >
                <Box className={classes.chips}>
                  {[UserRole.SUPER_ADMIN, UserRole.ADMIN]?.includes(user?.role?.roleName)
                    && <Tooltip title="delete">
                      <IconButton size="small" onClick={(e) => handleOpenDeleteModal(e)} color="error">
                        <img src={deleteIcon} alt="delete-report" width={24} />
                      </IconButton>
                    </Tooltip>}
                  <Tooltip title="Download Excel">
                    <IconButton
                      disabled={exportExcel}
                      onClick={(e) => {
                        e.stopPropagation();
                        reportExportToExecl(test?.id, test?.testReportName, test?.fileType);
                      }}
                      size="small"
                      cursor="pointer"
                    >
                      <img width={24} src={excelIcon} alt="export-report" />
                    </IconButton>
                  </Tooltip>
                  {test?.automationReportKey !== null && <Tooltip title="Download Report">
                    <IconButton
                      disabled={downloading}
                      onClick={(e) => {
                        reportDownload('AUTOMATION_REPORT', test?.testReportName, test?.automationReportKey, test?.fileType);
                        e.stopPropagation();
                      }}
                      cursor="pointer"
                      size="small"
                    >
                      <img width={24} src={test?.fileType !== FileType.XML ? HTMLIcon : XMLIcon} alt="download-report" />
                    </IconButton>
                  </Tooltip>}
                  {test?.jenkinsBuildVersion !== null && <Tooltip title="Build version">
                    <Badge max={Number.MAX_VALUE} badgeContent={test?.jenkinsBuildVersion} color="primary" sx={{ marginRight: '0px' }}>
                      <img src={jenkinsImg} alt="build-version" width={24} />
                    </Badge>
                  </Tooltip>}
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>);
      })
    ) : (
      <Typography variant="body1" color="textSecondary" align="center">
        No Data Found
      </Typography>
    )}
    {!!tests?.length && isPagination && (
      <Box className={classes.paginationContainer}>
        <StyledTablePagination
          count={totalPages * rowsPerPage}
          totalPages={totalPages}
          page={page - 1}
          onPageChange={onPageChange}
          ActionsComponent={() => <CustomPagination
            count={totalPages}
            page={page}
            onChange={onPageChange}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    )}
    {openDeleteModal && <DeleteReportModal
      open={openDeleteModal}
      handleClose={() => setOpenDeleteModal(false)}
      onConfirm={handleDeleteReport}
      reportName={selectedTest?.testReportName} // Pass the report name to display
    />}
  </>);
};

export default TestExecutionList;

TestExecutionList.propTypes = {
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      testReportName: PropTypes.string.isRequired,
      customUserResponse: PropTypes.shape({
        username: PropTypes.string.isRequired,
      }),
      startedAt: PropTypes.string.isRequired,
      passed: PropTypes.number.isRequired,
      failed: PropTypes.number.isRequired,
      skipped: PropTypes.number.isRequired,
      ignored: PropTypes.number.isRequired,
    }),
  ),
  totalPages: PropTypes.number,
  page: PropTypes.number,
  isPagination: PropTypes.bool,
  onTestClick: PropTypes.func,
  onPageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setSnackbar: PropTypes.func,
  isRefresh: PropTypes.bool,
  setIsRefresh: PropTypes.func,
};

TestExecutionList.defaultProps = {
  tests: [],
  totalPages: 1,
  page: 1,
  isPagination: false,
  onTestClick: () => { },
  onPageChange: () => { },
  handleChangeRowsPerPage: () => { },
  setSnackbar: () => { },
  isRefresh: false,
  setIsRefresh: () => { },
  rowsPerPage: 1,
};
