/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Grid,
  TextField,
  Typography,
  Box,
  FormControl,
  Input,
  InputLabel,
  Button,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  ListItemText,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import {
  AvatarStyle,
  ButtonsStyle,
  ParentBox,
} from './style';
import userService from '../../services/user.service';
import {
  FileType,
  UserPermission, UserPermissionsObject, UserRole, UserRolesOject,
} from '../../utils/constants';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { useCommonContext } from '../header/context';
import PasswordDialog from './PasswordDialog';
import AccessTokens from '../accessTokens/AccessToken';
import { addSlackWebHook, deleteSlackWebHook, getSlackWebHook } from '../../services/integrations';
import JiraIntegration from '../Integrations/JiraIntegration';
import JiraIcon from '../../assets/jira-svgrepo-com.svg';
import SlackIcon from '../../assets/slack-svgrepo-com.svg';
import CameraIcon from '../../assets/cameraIcon.svg';
import framework1 from '../../assets/images/framework1.svg';
import framework2 from '../../assets/images/framewor2.svg';
import framework3 from '../../assets/images/framewor3.svg';
import route from '../../utils/route';

const baseStyle = {
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '20px',
  borderRadius: 2,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  borderBottom: '1px solid #E7E7E7',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    background: '#f5f5f5',
  },
  heading: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    borderBottom: '4px solid #FFC72B',
    fontWeight: '600',
    paddingBlock: 8,
  },
  tabsMain: {
    background: '#f9f9f9',
    display: 'flex',
    width: 'fit-content',
    border: '1px solid #e1e1e1',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(4),
  },
  balloonInActive: {
    backgroundColor: '#1D6AE5',
    color: '#FFF !important',
    textTransform: 'capitalize',
    minWidth: '120px',
    '&:not(:last-child)': {
      borderRight: '1px solid #1D6AE5',
    },
    fontSize: '15px',
  },
  balloon: {
    backgroundColor: '#fff',
    color: '#666',
    textTransform: 'capitalize',
    minWidth: '120px',
    '&:not(:last-child)': {
      borderRight: '1px solid #e1e1e1',
    },
    fontSize: '15px',
  },
  inputFeild: {
    width: '50%',
    marginInline: 'auto',
  },
}));

const tabItems = [
  { label: 'Account Setting', icon: <AccountCircleOutlinedIcon /> },
  { label: 'Security', icon: <PrivacyTipOutlinedIcon /> },
  { label: 'Selenium Plugin', key: FileType.SELENIUM, icon: <img width="18px" src={framework1} alt="selenium" /> },
  { label: 'Playwright Plugin', key: FileType.PLAYWRIGHT, icon: <img width="18px" src={framework2} alt="playwright" /> },
  { label: 'Cypress Plugin', key: FileType.CYPRESS, icon: <img width="18px" src={framework3} alt="cyress" /> },
  { label: 'Slack Integrations', icon: <img width="18px" src={SlackIcon} alt="slack" />, disabled: true },
  { label: 'Jira Integrations', icon: <img width="18px" src={JiraIcon} alt="jira" />, disabled: true },
];
export function CustomTabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PersonalInfo() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [value, setValue] = React.useState(0);
  const [isDisabled] = useState(true);
  const [slackUrl, setSlackUrl] = useState('');
  const [webHookUrl, setWebHookUrl] = useState({});
  const [refresh, setRefresh] = useState(false);
  const {
    setUser,
    user,
  } = useCommonContext();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [userDetail, setUserDetail] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    image: null,
    role: UserRole.ADMIN,
    username: '',
    googleId: '',
    id: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: 'image/*',
    noClick: editUser,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        setUser({ ...user, image: URL.createObjectURL(acceptedFiles?.[0]) });
      }
    },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject],
  );
  const handleEditInfo = () => {
    setEditUser(false);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      firstName: '',
      lastName: '',
      mobile: '',
    };

    // Validate First Name (non-empty and minimum length of 3)
    if (!userDetail.firstName.trim()) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    } else if (userDetail.firstName.trim().length < 3) {
      newErrors.firstName = 'First name must be at least 3 characters long';
      isValid = false;
    }

    // Validate Last Name (non-empty and minimum length of 3)
    if (!userDetail.lastName.trim()) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    } else if (userDetail.lastName.trim().length < 3) {
      newErrors.lastName = 'Last name must be at least 3 characters long';
      isValid = false;
    }

    // Validate Mobile (numbers only, length check)
    const mobileRegex = /^(?:\+?\d{1,3})?\s?\d{10}$/;
    if (!mobileRegex.test(userDetail.mobile)) {
      newErrors.mobile = 'Enter a valid mobile number';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSaveUserInfo = async () => {
    setEditUser(true);
    try {
      if (!validateForm()) {
        setEditUser(false);
        throw new Error('Invalid Details');
      }
      const formData = new FormData();
      const userInfo = {
        id: userDetail.id,
        firstName: userDetail?.firstName,
        lastName: userDetail?.lastName,
        email: userDetail?.email,
        mobile: userDetail?.mobile,
      };
      formData.append('image', selectedFile);
      formData.append('userRequest', JSON.stringify(userInfo));
      await userService.userUpdate(formData);
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Updated Successfully',
      });
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message || err?.message}`,
      });
    }
  };
  const handleSubmit = async () => {
    if (slackUrl.length) {
      try {
        const res = await addSlackWebHook(slackUrl);
        if (res) {
          setRefresh(res);
          setSlackUrl('');
          setSnackbar({
            navigateText: '',
            severity: 'success',
            state: true,
            message: 'Added Successfully',
          });
        }
      } catch (err) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${err?.data?.message}`,
        });
      } finally {
        setRefresh(false);
      }
    }
  };
  const removeWebHook = async () => {
    try {
      await deleteSlackWebHook();
      setWebHookUrl('');
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Deleted Successfully',
      });
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message}`,
      });
    }
  };
  useEffect(() => {
    if (user) {
      setUserDetail((pre) => ({
        ...pre,
        ...user,
      }));
    }
  }, [user]);
  useEffect(async () => {
    try {
      const res = await getSlackWebHook();
      setWebHookUrl(res);
    } catch (err) {
      console.log(err.data?.message);
    }
  }, [refresh]);

  const handleSetPassword = async (newPassword) => {
    try {
      await userService.changePassword({ newPassword });
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Change password Successfully...',
      });
    } catch (err) {
      if (err) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${err?.data?.message || err?.message}`,
        });
      }
    }
  };
  return (
    <ParentBox>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ mt: 3, zIndex: '1' }}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
            <Typography color="#666666" onClick={() => navigate(`/${route.ROUTE_DASHBOARD}`)} sx={{ cursor: 'pointer' }}>Dashboard</Typography>
            <Typography color="#666666">Profile</Typography>
          </Breadcrumbs>
          <Box className={classes.heading}>
            <Typography className={classes.title} variant="h4">
              Profile
            </Typography>
          </Box>
        </Grid>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none" // Disable default tab underline
          className={classes.tabsMain}
        >
          {tabItems.map((item, index) => (
            <Tab
              key={index}
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                minHeight: '50px',
                display: item.key ? (user?.framework.includes(item.key) ? 'flex' : 'none') : 'flex',
              }}
              icon={item.icon}
              iconPosition="start"
              label={item.label}
              disabled={item.disabled}
              className={value === index ? classes.balloonInActive : classes.balloon}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Grid container sx={{ position: 'relative', mt: 3 }}>
          <Grid
            item
            xs={9}
          >
            <Card sx={{ display: 'flex' }}>
              <Box width="65%" borderRight="1px solid #E7E7E7">
                <CustomTabPanel value={value} index={0}>
                  <Grid sx={{ textAlign: 'center' }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        flexDirection: 'column', alignItems: 'center', gap: 2, justifyContent: 'center', marginBlock: 4,
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ width: '100%' }}
                        data-testid="PersonalInfo-box"
                      >
                        <TextField
                          className={classes.inputFeild}
                          fullWidth
                          label="First Name"
                          value={userDetail.firstName}
                          disabled={editUser}
                          id="Firstname"
                          onChange={(e) => setUserDetail({
                            ...userDetail,
                            firstName: e.target.value,
                          })}
                          inputProps={{ 'data-testid': 'name-field' }}
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ width: '100%' }}
                        data-testid="PersonalInfo-box"
                      >
                        <TextField
                          className={classes.inputFeild}
                          label="Last Name"
                          fullWidth
                          value={userDetail.lastName}
                          disabled={editUser}
                          id="Lastname"
                          onChange={(e) => setUserDetail({
                            ...userDetail,
                            lastName: e.target.value,
                          })}
                          inputProps={{ 'data-testid': 'name-field' }}
                          error={!!errors.lastName}
                          helperText={errors.lastName}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ width: '100%' }}
                        data-testid="PersonalInfo-box"
                      >
                        <TextField
                          className={classes.inputFeild}
                          fullWidth
                          label="Phone"
                          value={userDetail.mobile}
                          disabled={editUser}
                          id="phone"
                          onChange={(e) => setUserDetail({ ...userDetail, mobile: e.target.value })}
                          inputProps={{ 'data-testid': 'name-field' }}
                          error={!!errors.mobile}
                          helperText={errors.mobile}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ width: '100%' }}
                      >
                        <TextField
                          className={classes.inputFeild}
                          label="Email Address"
                          fullWidth
                          value={userDetail.email}
                          disabled={isDisabled}
                          id="EmailAddress"
                          onChange={(e) => setUserDetail({ ...userDetail, email: e.target.value })}
                          inputProps={{ 'data-testid': 'email-field' }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ width: '100%', textAlign: 'start' }}
                      >
                        <Grid className={classes.inputFeild} display="flex" alignItems="flex-start" gap={2}>
                          <Button
                            variant="contained"
                            disabled={editUser}
                            onClick={() => handleSaveUserInfo()}
                          >
                            save
                          </Button>
                          <Button
                            variant="contained"
                            disabled={!editUser}
                            onClick={() => handleEditInfo()}
                          >
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <PasswordDialog
                          onSetPassword={handleSetPassword}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  {user?.framework?.includes(FileType.SELENIUM)
                    ? <Box sx={{ p: 2 }}>
                      <Typography variant="h6" marginBlock={2}>Project Integration</Typography>
                      <AccessTokens framework={FileType.SELENIUM} />
                    </Box>
                    : <>
                      <Typography variant="h5" fontWeight="bold">Not Authorized</Typography>
                      <Typography variant="body2" marginTop={2}>You do not have Permission to access Maven Plugin</Typography>
                    </>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  {user?.framework?.includes(FileType.PLAYWRIGHT)
                    ? <Box sx={{ p: 2 }}>
                      <Typography variant="h6" marginBlock={2}>Project Integration</Typography>
                      <AccessTokens framework={FileType.PLAYWRIGHT} />
                    </Box>
                    : <>
                      <Typography variant="h5" fontWeight="bold">Not Authorized</Typography>
                      <Typography variant="body2" marginTop={2}>You do not have Permission to access Maven Plugin</Typography>
                    </>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  {user?.framework?.includes(FileType.CYPRESS)
                    ? <Box sx={{ p: 2 }}>
                      <Typography variant="h6" marginBlock={2}>Project Integration</Typography>
                      <AccessTokens framework={FileType.CYPRESS} />
                    </Box>
                    : <>
                      <Typography variant="h5" fontWeight="bold">Not Authorized</Typography>
                      <Typography variant="body2" marginTop={2}>You do not have Permission to access Maven Plugin</Typography>
                    </>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                  {user?.role?.userPermissionsList?.includes(UserPermission.SLACK_INTEGRATION)
                    ? <>
                      <Box sx={{ p: 2 }}>
                        <Typography mb={2} variant="h6">Slack Integration</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={8} sm={4}>
                            <FormControl sx={{ width: '100%' }}>
                              <InputLabel size="small">Slack Web Hook Url</InputLabel>
                              <Input
                                value={slackUrl}
                                onChange={(e) => setSlackUrl(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={8} sm={4}>
                            <Button
                              type="submit"
                              onClick={handleSubmit}
                              sx={{ borderRadius: '4px' }}
                              size="large"
                              variant="contained"
                              disabled={!slackUrl}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid py={2} container spacing={2}>
                          <Grid item xs={12}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow sx={{ bgcolor: '#F6F7F8' }}>
                                    <TableCell>WebHookUrl</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell sx={{ maxWidth: '70%', wordBreak: 'break-word' }}>{webHookUrl?.webhookUrl || 'No webhook URL set'}</TableCell>
                                    {webHookUrl?.webhookUrl && <TableCell>
                                      <IconButton color="error" size="small" onClick={removeWebHook}>
                                        <DeleteIcon fontSize="small" />
                                        Remove
                                      </IconButton>
                                    </TableCell>}
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                    : <>
                      <Typography variant="h5" fontWeight="bold">Not Authorized</Typography>
                      <Typography variant="body2" marginTop={2}>You do not have Permission to access SLACK INTEGRATION</Typography>
                    </>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                  {user?.role?.userPermissionsList?.includes(UserPermission.JIRA_INTEGRATION)
                    ? <>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="h6">JIRA INTEGRATION</Typography>
                        <JiraIntegration />
                      </Box>
                    </>
                    : <>
                      <Typography variant="h5" fontWeight="bold">Not Authorized</Typography>
                      <Typography variant="body2" marginTop={2}>You do not have Permission to access Jira Integration</Typography>
                    </>}
                </CustomTabPanel>
              </Box>
              <Box width="35%">
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    p: 4,
                  }}
                >
                  <div {...getRootProps({ style })}>
                    <AvatarStyle alt={userDetail?.firstName} src={userDetail?.image} />
                    <input {...getInputProps()} />
                    <Grid sx={{ background: '#fff' }}>
                      <ButtonsStyle type="button" variant="contained" onClick={open} disabled={editUser}>
                        <img width="100%" src={CameraIcon} alt="capture" />
                      </ButtonsStyle>
                    </Grid>
                    <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
                      {userDetail.firstName}
                      {' '}
                      {userDetail.lastName}
                    </Typography>
                  </div>
                  <Grid display="flex" flexDirection="column" alignItems="flex-start" sx={{ width: '100%', mt: 2 }} gap={3}>
                    {userDetail.mobile && <ListItemText
                      primary="Phone"
                      secondary={userDetail.mobile}
                      primaryTypographyProps={{
                        style: {
                          fontSize: '17px',
                          color: '#A9A9A9',
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: '15px',
                          color: '#000000',
                        },
                      }}
                    />}
                    <ListItemText
                      primary="Role"
                      secondary={UserRolesOject[userDetail?.role?.roleName]}
                      primaryTypographyProps={{
                        style: {
                          fontSize: '17px',
                          color: '#A9A9A9',
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: '15px',
                          color: '#000000',
                        },
                      }}
                    />
                    <ListItemText
                      primary="Permissions"
                      secondary={userDetail?.role?.userPermissionsList?.map((i) => UserPermissionsObject[i]).join(', ')}
                      primaryTypographyProps={{
                        style: {
                          fontSize: '17px',
                          color: '#A9A9A9',
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: '15px',
                          color: '#000000',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </ParentBox>
  );
}

export default PersonalInfo;
