import * as React from 'react';
import PropTypes from 'prop-types';

const context = React.createContext('');
export default function CommonContext({ children }) {
  const token = localStorage.getItem('token');
  const [toggleHeader, setToggleHeader] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [reportType, setReportType] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);
  const [totalElements, setTotalElements] = React.useState(null);
  const [selectedfilter, setSelectedFilter] = React.useState(null);
  const [countNotification, setCountNotification] = React.useState(0);
  const [notification, setNotification] = React.useState([]);
  const [bugTag, setBugTag] = React.useState(false);
  const [errorTag, setErrorTag] = React.useState(false);
  const [notBugTag, setNotBugTag] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState('Home');
  const [buildRunTab, setBuildRunTab] = React.useState(0);
  const [countNotify, setCounNotify] = React.useState(null);

  React.useEffect(() => {
    if (!token) {
      setUser(null);
    }
  }, [token]);

  React.useEffect(() => {
    if (user) setReportType(user?.framework[0] || '');
  }, [user]);

  return (
    <context.Provider
      value={{
        user,
        setUser,
        toggleHeader,
        setToggleHeader,
        reportType,
        setReportType,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalElements,
        setTotalElements,
        selectedfilter,
        setSelectedFilter,
        countNotification,
        setCountNotification,
        notification,
        setNotification,
        bugTag,
        setBugTag,
        errorTag,
        setErrorTag,
        notBugTag,
        setNotBugTag,
        activeItem,
        setActiveItem,
        buildRunTab,
        setBuildRunTab,
        countNotify,
        setCounNotify,
      }}
    >
      {children}
    </context.Provider>
  );
}

CommonContext.propTypes = {
  children: PropTypes.element,
};
CommonContext.defaultProps = {
  children: PropTypes.element,
};

function useCommonContext() {
  return React.useContext(context);
}
export { useCommonContext };
