/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DashboardEnum } from '../../utils/constants';
import inviteUserService from '../../services/inviteUser.service';
import userService from '../../services/user.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const renderSeleniumIntegrationSteps = [
  {
    label: 'Step 1',
    title: 'Copy Snippet',
    description: '',
  },
  {
    label: 'Step 2',
    title: 'Open POM.xml file from your selenium framework.',
    description: '',
  },
  {
    label: 'Step 3',
    title: 'Set sourceFilePath',
    description: (
      <>
        <Typography>
          Set the path of the
          <code>{' index.html '}</code>
          file in your projects where the file generates inside the OutputDirectory tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>{'<sourceFilePath> add your project test file path </sourceFilePath>'}</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Set screenshotPath',
    description: (
      <>
        <Typography>
          Set the screenshot path to the image of your project directory to set the path of failed
          cases.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>{'<screenshotImagePath>./fail-safe-reports/chrome/screenshot/ </screenshotImagePath>'}</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Run',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          <code>{'mvn clean install '}</code>
          command on terminal**
        </Typography>
      </Box>
    ),
  },
];

const renderPlaywrightIntegrationSteps = [
  {
    label: 'Step 1',
    title: 'Copy Code',
    description: (
      <>
        <Typography>
          Create a configuration file named
          <code>{' testreport.config.js '}</code>
          in the root directory of your Playwright project.
          Then, paste the
          <b> module.exports </b>
          inside the file.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Install testreport-io Dependency',
    description: (
      <>
        <Typography>
          First, add the
          <code>{' testreport-io '}</code>
          dependency to your project by running the following command:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>npm install testreport-io</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Add the Upload Script',
    description: (
      <>
        <Typography>
          Finally, add the following script to your
          <code>{' package.json '}</code>
          file to enable uploading your test reports:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>upload: testreport-io upload</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Set sourceFilePath',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          Set the path of the
          <code>{' index.html '}</code>
          file in your project where the file is generated inside the
          <code>{' OutputDirectory '}</code>
          tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>
              &lt;sourceFilePath&gt;add your project test file path&lt;/sourceFilePath&gt;
            </code>
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    label: 'Step 5',
    title: 'Set screenshotPath',
    description: (
      <>
        <Typography>
          Set the screenshot path to the image directory of your project to capture failed cases.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>/playwright/screenshots</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 6',
    title: 'Run',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          <code>{'npm run upload '}</code>
          command on terminal**
        </Typography>
      </Box>
    ),
  },
];

const renderCypressIntegrationSteps = [
  {
    label: 'Step 1',
    title: 'Copy Code',
    description: (
      <>
        <Typography>
          Create a configuration file named
          <code>{' testreport.config.js '}</code>
          in the root directory of your Cypress project.
          Then, paste the
          <b> module.exports </b>
          inside the file.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Install testreport-io Dependency',
    description: (
      <>
        <Typography>
          First, add the
          <code>{' testreport-io '}</code>
          dependency to your project by running the following command:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>npm install testreport-io</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Add the Upload Script',
    description: (
      <>
        <Typography>
          Finally, add the following script to your
          <code>{' package.json '}</code>
          file to enable uploading your test reports:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>upload: testreport-io upload</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Set sourceFilePath',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          Set the path of the
          <code>{' index.html '}</code>
          file in your project where the file is generated inside the
          <code>{' OutputDirectory '}</code>
          tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>
              &lt;sourceFilePath&gt;add your project test file path&lt;/sourceFilePath&gt;
            </code>
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    label: 'Step 5',
    title: 'Set screenshotPath',
    description: (
      <>
        <Typography>
          Set the screenshot path to the image directory of your project to capture failed cases.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>/cypress/screenshots</code>
          </Typography>
        </Box>
        <Typography>
          Set the video path to the video directory of your project to capture failed cases.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>/cypress/video</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 6',
    title: 'Run',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          <code>{'npm run upload '}</code>
          command on terminal**
        </Typography>
      </Box>
    ),
  },
];

const StepUpSteps = ({ companyDetails }) => {
  const [token, setToken] = useState(null);
  const [projectUrl, setprojectUrl] = useState('');
  const [userDetails, setUserDetails] = useState({
    projectId: companyDetails?.projectId || '',
    companyId: companyDetails?.companyId || '',
    selectedFramework: companyDetails?.selectedFramework || '',
  });
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [code, setCode] = useState('');

  useEffect(() => {
    if (projectUrl && token && userDetails.selectedFramework) {
      let codeString = '';

      switch (userDetails.selectedFramework) {
        case DashboardEnum.SELENIUM:
          codeString = `
<build>
  <plugins>
    <plugin>
      <groupId>com.decipherzone</groupId>
      <artifactId>qa-parse-maven-plugin</artifactId>
      <version>1.5.12</version>
      <executions>
        <execution>
          <phase>integration-test</phase>
          <configuration>
            <projectBaseUrl>${projectUrl}</projectBaseUrl>
            <screenshotImagePath>/failsafe-reports/chrome/screenshots/</screenshotImagePath>
            <sourceFilePath>/platform-server/testng-results.xml</sourceFilePath>
            <projectAccessKey>${token}</projectAccessKey>
          </configuration>
          <goals>
            <goal>xmltojson</goal>
          </goals>
        </execution>
      </executions>
    </plugin>
  </plugins>
</build>`;
          break;

        case DashboardEnum.PLAYWRIGHT:
          codeString = `module.exports = {
    projectAccessKey:${token},
    projectBaseUrl: ${projectUrl},
    targetDir: '<generated test report index.html directory path>',
    screenshotsDir: '<screenshots directory path>',
  };`;
          break;

        case DashboardEnum.CYPRESS:
          codeString = `module.exports = {
    projectAccessKey:${token},
    projectBaseUrl: ${projectUrl},
    targetDir: '<generated test report index.html directory path>',
    screenshotsDir: '<screenshots directory path>',
    videoDir: '<videos directory path>'
  };`;
          break;

        default:
          codeString = '';
      }

      setCode(codeString);
    }
  }, [projectUrl, token, userDetails]);

  const getUserDetails = async () => {
    try {
      const user = await userService.userInfo();
      setUserDetails({
        projectId: user.company.projectResponses[0].projectId,
        companyId: user.company.companyId,
        companyName: user.company.companyName,
        projectName: user.company.projectResponses[0].projectName,
        selectedFramework: user.company.projectResponses[0].framework,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const accessToken = async () => {
      if (userDetails.companyId && userDetails.projectId) {
        try {
          const res = await inviteUserService.accessToken(
            userDetails.companyId,
            userDetails.projectId,
          );
          if (res) {
            setToken(res?.accessKey);
            setprojectUrl(res?.projectBasicUrl);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    accessToken();
  }, [userDetails]);

  const renderIntegrationContent = () => {
    switch (userDetails?.selectedFramework) {
      case DashboardEnum.SELENIUM:
        return renderSeleniumIntegrationSteps;
      case DashboardEnum.PLAYWRIGHT:
        return renderPlaywrightIntegrationSteps;
      case DashboardEnum.CYPRESS:
        return renderCypressIntegrationSteps;
      default:
        return [];
    }
  };

  useEffect(() => {
    if (userDetails !== undefined || !userDetails?.companyId) getUserDetails();
  }, []);

  return (
    <>
      <div className="multistep-main-wrap">
        <div style={{ width: '100%', padding: '0 8px' }} className="multistep-2-block-details">
          <div className="multistep-detail-block" style={{ position: 'relative' }}>
            <div style={{ display: 'flex', flexDirection: 'column-reverse' }} className="multistep-detail-block">
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: 1,
                  fontFamily: 'monospace',
                }}
              >
                <div>
                  <Tooltip title="Copy to Clipboard">
                    <ContentCopyIcon
                      color="primary"
                      style={{ cursor: 'pointer', marginBottom: '10px' }}
                      onClick={() => navigator.clipboard.writeText(code)}
                    />
                  </Tooltip>
                  <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                    <code>{code}</code>
                  </Typography>
                </div>
              </Box>
              <Box sx={{ width: '100%', padding: 4 }}>
                <Stepper activeStep={-1} orientation="vertical">
                  {renderIntegrationContent()?.length
                    ? renderIntegrationContent()?.map((step, index) => (
                      <Step key={index}>
                        <div className="copy-icon">
                          <StepLabel>
                            <Typography variant="p" sx={{ fontWeight: 'light', color: '#333' }}>
                              {step.label}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                              {step.title}
                            </Typography>
                          </StepLabel>
                          {step.label === 'Step 1' && <Tooltip title="Copy to Clipboard">
                            <ContentCopyIcon
                              color="primary"
                              style={{ cursor: 'pointer', marginBottom: '10px' }}
                              onClick={() => navigator.clipboard.writeText(code)}
                            />
                          </Tooltip>}
                        </div>
                        <Box sx={{ marginTop: 2, paddingLeft: 4 }}>
                          <Typography variant="body1" sx={{ color: '#555' }}>
                            {step.description}
                          </Typography>
                        </Box>
                      </Step>
                    )) : <p>Select a valid framework to see integration instructions.</p>}
                </Stepper>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </>
  );
};

export default StepUpSteps;

StepUpSteps.propTypes = {
  companyDetails: PropTypes.shape({
    projectId: PropTypes.number,
    companyId: PropTypes.number,
    selectedFramework: PropTypes.string,
  }),
};

StepUpSteps.defaultProps = {
  companyDetails: {},
};
