import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
export const options = {
  plugins: {
    title: {
      display: false, // Hide title
    },
    legend: {
      display: false, // Hide legend
    },
    tooltip: {
      enabled: true,
      displayColors: true,
      callbacks: {
        label: (tooltipItem) => `${tooltipItem.raw}`,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false, // Disable the default aspect ratio
  scales: {
    x: {
      display: false, // Hide x-axis
      stacked: true,
      grid: {
        display: false, // Remove x-axis grid lines
      },
    },
    y: {
      display: false, // Hide y-axis
      stacked: true,
      grid: {
        display: false, // Remove y-axis grid lines
      },
    },
  },
  layout: {
    padding: 0, // Remove padding
  },
  chartArea: {
    backgroundColor: null, // No background color
  },
};

function ColunmChart({ loading, labels, datasets }) {
  const data = {
    labels,
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label || `Data ${index + 1}`,
      data: dataset.data,
      backgroundColor: ['#5FD726', '#FF0000', '#FFC72B', '#000000'], // Colors from your pie chart
      borderWidth: 0, // No border
    })),
  };
  return <div style={{ width: '70px', height: '50px' }}>
    {loading ? (
      <Skeleton variant="rounded" />
    ) : (
      <Bar options={options} data={data} />
    )}
  </div>;
}
ColunmChart.propTypes = {
  labels: PropTypes.string.isRequired,
  datasets: PropTypes.objectOf.isRequired,
  loading: PropTypes.bool,
};
ColunmChart.defaultProps = {
  loading: false,
};

export default ColunmChart;
