/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Box, Typography, Button, Chip,
  Tooltip,
  ListItemText,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import stopwatchIcon from '../../../assets/stopwatch.svg';
import DonutChart from '../../chart/DonutChart';
import { getDuration } from '../../Home/TestData';
import { HtmlStatus } from '../../../utils/constants';
import { useCommonContext } from '../../header/context';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    border: '1px solid #000000',
    margin: theme.spacing(2, 0),
    boxShadow: '-5px 5px 0px 0px #FFC72B',
    borderRadius: theme.spacing(1),
  },
  summaryTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: '19px',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  testHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    borderBottom: '1px solid #e0e0e0',
  },
  stepDetails: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  TAG_BUTTON: {
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
  },
  TO_INVESTIGATE: {
    backgroundColor: '#000000',
    color: '#fff',
    '&:hover': {
      background: '#A6A6A6',
    },
  },
  PRODUCT_BUG: {
    backgroundColor: '#FF0000',
    '&:hover': {
      background: '#BF0000',
    },
  },
  AUTOMATION_BUG: {
    backgroundColor: '#FFC72B',
    '&:hover': {
      background: '#CC9A22',
    },
  },
  NO_DEFECT: {
    backgroundColor: '#1D6AE5',
    '&:hover': {
      background: '#154EB0',
    },
  },
  ENVIRONMENT_ISSUE: {
    backgroundColor: '#5FD726',
    '&:hover': {
      background: '#4AA61E',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
    '&:hover': {
      background: '#EFEFEF',
    },
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
}));

const SeleniumHTMLTestSteps = ({
  tags, testData, changeTest, openTest, project, id,
}) => {
  const classes = useStyles();
  const { selectedfilter } = useCommonContext();

  let listData = testData?.suite;
  if (listData?.test) {
    if (!Array.isArray(listData?.test)) {
      listData = [listData?.test];
    } else {
      listData = listData?.test;
    }
  }

  return (
    <>
      <Box className={classes.testHeaderMain}>
        <Box>
          <Typography className={classes.summaryTitle} variant="h6">{testData?.suite?.name}</Typography>
        </Box>
        <Box className={classes.stepSummary}>
          <Box className={classes.stepIcons}>
            <Box mx={2} display="flex" alignItems="center">
              <img src={stopwatchIcon} width={20} alt="Stopwatch" />
              <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(testData?.totalTime || 0)}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            <Tooltip title="Passed">
              <Chip
                variant="outlined"
                label={testData?.passed}
                className={classes.chipSuccess}
              />
            </Tooltip>
            <Tooltip title="Failed">
              <Chip
                variant="outlined"
                label={testData?.failed}
                className={classes.chipFailure}
              />
            </Tooltip>
            <Tooltip title="Skipped">
              <Chip
                variant="outlined"
                label={testData?.skipped}
                className={classes.chipSkipped}
              />
            </Tooltip>
            <Tooltip title="Retried">
              <Chip
                variant="outlined"
                label={testData?.retried}
                className={classes.chipIgnored}
              />
            </Tooltip>
          </Box>
          <Box>
            <DonutChart
              donutChartSuitesData={{
                passed: testData?.passed,
                failed: testData?.failed,
                skipped: testData?.skipped,
                ignored: testData?.retried,
              }}
            />
          </Box>
        </Box>
      </Box>
      {listData && listData?.map((item) => {
        const methods = item?.testResultClass?.testMethod || [];

        // Skip the step if methods is an empty array
        if (!methods.length) return null;

        const itemArray = Array.isArray(methods) ? methods : [methods];
        const hasException = itemArray?.some((itemA) => itemA?.exception != null);

        const filteredMethods = itemArray?.filter((method) => {
          if (selectedfilter === HtmlStatus.PASSED && !hasException) {
            return method.status === HtmlStatus.PASSED;
          }
          if (selectedfilter === HtmlStatus.FAILED && !hasException) {
            return method.status === HtmlStatus.FAILED;
          }
          return true;
        });

        let failedTest = [];

        if (hasException && filteredMethods?.length) {
          failedTest = filteredMethods;
        } else if (filteredMethods?.length) {
          failedTest = filteredMethods.filter((a) => a.status === HtmlStatus.FAILED);
        }

        if (selectedfilter === HtmlStatus.PASSED && failedTest.length) {
          return null;
        }
        if (selectedfilter === HtmlStatus.FAILED && failedTest.length === 0) {
          return null;
        }

        const isFailed = hasException;
        const tagClass = tags[item.name]?.tagName;

        return <>
          <Box
            onClick={() => {
              changeTest({
                id, test: item?.testResultClass, testName: item.name, project,
              }); openTest(true);
            }}
            className={classes.testWrapper}
          >
            <Box className={classes.stepHeader}>
              <Box className={classes.stepTitle}>
                <CircleIcon className={isFailed ? classes.iconError : classes.iconSuccess} />
                <Box>
                  <ListItemText
                    sx={{ flex: 1 }}
                    primary={item?.name}
                    secondary={`${item?.testResultClass?.methodName}`}
                  />
                </Box>
              </Box>
              <Box className={classes.stepSummary}>
                {isFailed
                  && <Button
                    className={`${classes.TAG_BUTTON} ${tagClass !== undefined
                      ? classes[tagClass]
                      : classes.TO_INVESTIGATE
                    }`}
                  >
                    {tagClass || 'TO INVESTIGATE'}
                  </Button>}
                <Box className={classes.stepIcons}>
                  <Box mx={2} display="flex" alignItems="center">
                    <img width={20} src={stopwatchIcon} alt="stopwatch" />
                    <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(item?.testResultClass?.duration) || 0}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>;
      })}
    </>
  );
};

export default SeleniumHTMLTestSteps;

SeleniumHTMLTestSteps.propTypes = {
  tags: {},
  testData: [],
  changeTest: PropTypes.func,
  openTest: PropTypes.func,
  project: PropTypes.string,
  id: PropTypes.string,
};

SeleniumHTMLTestSteps.defaultProps = {
  tags: {},
  testData: [],
  changeTest: () => { },
  openTest: () => { },
  project: '',
  id: '',
};
